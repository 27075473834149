body {
  &, * {
    font-family: "Poppins", "Helvetica", "sans-serif";
    font-weight: 300;
    box-sizing: border-box;
    text-decoration: none;
    text-underline: none;
  }

  & {
    margin: 0;
    padding: 0;
  }
}

body {
  background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  color: #023766;
}

.print-document-footer,
.print-document-header,
.print-document-header-space,
.print-document-footer-space {
  height: 100px;
  width: 100%;
}

.print-document-header, .print-document-footer {
  position: fixed;
}

.print-document-header {
  top: 0;
}

.print-document-footer {
  bottom: 0;
}
@page {
  margin: 2mm
}
